var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[_c('div',{staticClass:"form-wrapper full"},[_c('image-selector',{attrs:{"defaultImage":_vm.organization.logo,"ressource_name":"organizations","ressource_id":_vm.organization.id ? _vm.organization.id : 0,"field":"logo"},on:{"imageChanged":(file_url) => {
            _vm.organization.logo = file_url;
            _vm.onFormChanged();
          }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}})],1),_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION_INFORMATION")))])]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.RESELLER')} (*)`,"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.organization.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":(resellerId) => {
              _vm.organization.reseller.id = resellerId;
              _vm.organization.owner.id = null;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.excerpt),callback:function ($$v) {_vm.$set(_vm.organization, "excerpt", $$v)},expression:"organization.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`,"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"equal-item type"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
                  _vm.organization.phone_type = type;
                  _vm.onFormChanged();
                }},model:{value:(_vm.organization.phone_type),callback:function ($$v) {_vm.$set(_vm.organization, "phone_type", $$v)},expression:"organization.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"equal-item phone"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')}`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.organization.phone},on:{"phoneNumberChanged":(phone) => {
                  _vm.organization.phone = phone;
                  _vm.onFormChanged();
                }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"equal-item extension"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
                _vm.onFormChanged();
              }},model:{value:(_vm.organization.phone_extension),callback:function ($$v) {_vm.$set(_vm.organization, "phone_extension", $$v)},expression:"organization.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)])]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.organization.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
              _vm.organization.other_phones = phoneNumbers;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`,"placeholder":_vm.$t('COMMON.ADDRESS')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.address),callback:function ($$v) {_vm.$set(_vm.organization, "address", $$v)},expression:"organization.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1),_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))])]),_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.organization,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.SUBSCRIPTION")))])]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PACKAGES))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PACKAGE')} (*)`,"placeholder":_vm.$t('COMMON.PACKAGE')}},[_c('package-selector',{attrs:{"pack":_vm.subscription.package.id,"filterable":true,"showAll":false},on:{"packageChanged":(packageId) => {
              _vm.subscription.package.id = packageId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.package}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('SUBSCRIPTIONS.END_TIME')} (*)`}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.subscription.end_time),callback:function ($$v) {_vm.$set(_vm.subscription, "end_time", $$v)},expression:"subscription.end_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.end_time}})],1)],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onCloseOrganizationModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm kw-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.organization.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }