<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div class="form-wrapper full">
        <image-selector
          :defaultImage="organization.logo"
          ressource_name="organizations"
          :ressource_id="organization.id ? organization.id : 0"
          field="logo"
          @imageChanged="
            (file_url) => {
              organization.logo = file_url;
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.attachment" />
      </div>

      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.ORGANIZATION_INFORMATION") }}</h2>
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      >
        <base-input
          :label="`${$t('COMMON.RESELLER')} (*)`"
          :placeholder="$t('COMMON.RESELLER')"
        >
          <reseller-selector
            :reseller="organization.reseller.id"
            :filterable="true"
            :showAll="false"
            @resellerChanged="
              (resellerId) => {
                organization.reseller.id = resellerId;
                organization.owner.id = null;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.reseller" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :placeholder="$t('COMMON.NAME')"
          v-model="organization.name"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>

      <div class="form-wrapper full">
        <base-input
          type="textarea"
          :label="$t('COMMON.EXCERPT')"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="organization.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.EMAIL')} (*)`"
          :placeholder="$t('COMMON.EMAIL')"
          v-model="organization.email"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.email" />
      </div>

      <!-- Phone number -->
      <div class="form-wrapper full">
        <div class="row equal">
          <div class="equal-item type">
            <base-input :label="`${$t('COMMON.TYPE')}`">
              <el-select
                :label="$t('COMMON.TYPE')"
                :placeholder="$t('COMMON.TYPE')"
                v-model="organization.phone_type"
                @change="
                  (type) => {
                    organization.phone_type = type;
                    onFormChanged();
                  }
                "
              >
                <el-option
                  v-for="(value, key) in phoneTypesOptions"
                  :key="key"
                  :value="value"
                  :label="$t(`COMMON.PHONE_TYPE_${value}`)"
                />
              </el-select>
            </base-input>
          </div>
          <div class="equal-item phone">
            <base-input :label="`${$t('COMMON.PHONE')}`">
              <phone-number-input
                :phoneNumber="organization.phone"
                @phoneNumberChanged="
                  (phone) => {
                    organization.phone = phone;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.phone" />
          </div>
          <div class="equal-item extension">
            <base-input
              :label="`${$t('COMMON.EXTENSION')}`"
              v-model="organization.phone_extension"
              @change="
                () => {
                  onFormChanged();
                }
              "
              :inputClasses="'extension-input'"
            />
            <validation-error :errors="apiValidationErrors.phone_extension" />
          </div>
        </div>
      </div>

      <!-- Other phone numbers -->
      <div class="form-wrapper full">
        <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
          <phone-numbers-selector
            :phoneNumbers="organization.other_phones"
            @phoneNumbersChanged="
              (phoneNumbers) => {
                organization.other_phones = phoneNumbers;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.other_phones" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.ADDRESS')} (*)`"
          :placeholder="$t('COMMON.ADDRESS')"
          v-model="organization.address"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.address" />
      </div>

      <!-- <div class="form-wrapper taxes full">
        <taxes-selector
          :label="$t('COMMON.TAXES')"
          :taxes="organization.taxes"
          @taxesChanged="
            (taxes) => {
              organization.taxes = taxes;
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.taxes" />
      </div> -->

      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
      </div>

      <billing-informations-form
        :billingInformationsData="organization"
        :formErrors="formErrors"
        @onChangeBillingInformations="billingInformationsChanged"
      />

      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.SUBSCRIPTION") }}</h2>
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)"
      >
        <base-input
          :label="`${$t('COMMON.PACKAGE')} (*)`"
          :placeholder="$t('COMMON.PACKAGE')"
        >
          <package-selector
            :pack="subscription.package.id"
            :filterable="true"
            :showAll="false"
            @packageChanged="
              (packageId) => {
                subscription.package.id = packageId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.package" />
      </div>

      <div class="form-wrapper">
        <base-input :label="`${$t('SUBSCRIPTIONS.END_TIME')} (*)`">
          <flat-picker
            :config="{
              allowInput: true,
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="subscription.end_time"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.end_time" />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        class="btn cancel"
        type="button"
        @click="onCloseOrganizationModal"
      >
        {{ $t("COMMON.CANCEL") }}
      </base-button>
      <base-button
        type="button"
        class="btn btn-sm kw-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ organization.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
// import TaxesSelector from "@/components/TaxesSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import PackageSelector from "@/components/PackageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    // TaxesSelector,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
    PhoneNumbersSelector,
    PackageSelector,
    flatPicker,
  },

  mixins: [formMixin],

  props: ["organizationData", "formErrors", "loading", "subscriptionData"],

  data() {
    return {
      organization: { ...this.organizationData },
      subscription: { ...this.subscriptionData },
      permissions: [],
      models: models,
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let organizationData = cloneDeep(this.organization);
      if (organizationData.owner) {
        if (organizationData.owner.id == null) {
          organizationData.owner = null;
        }
      }
      organizationData = this.$fillUserResellerData(organizationData);

      this.subscription.start_time = moment(
        this.subscription.start_time
      ).toISOString();
      this.subscription.end_time = moment(
        this.subscription.end_time
      ).toISOString();

      this.$emit("organizationSubmitted", organizationData, this.subscription);
    },

    billingInformationsChanged(billingInformations) {
      this.organization = { ...this.organization, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onCloseOrganizationModal() {
      this.$emit("onCloseOrganizationModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    organizationData(organizationData) {
      if (organizationData) {
        this.organization = {
          ...this.organization,
          ...cloneDeep(organizationData),
        };
      }
    },
    subscriptionData(subscriptionData) {
      if (subscriptionData) {
        this.subscription = {
          ...this.subscription,
          ...cloneDeep(subscriptionData),
        };
      }
    },
  },
};
</script>
